/**
 * Initialize the kiosk back button, everywhere but the
 */
export const initKioskBackButton = () => {
    if (!shouldShowBackButton()) {
        return;
    }

    // Create back button
    const backButton = document.createElement('div')
    Object.assign(backButton, {
        className: 'kiosk__back-button'
    })

    const circle = document.createElement('div')
    Object.assign(circle, {
        className: 'kiosk__back-button-circle',
        innerHTML: '&#8678'
    })

    backButton.appendChild(circle);

    const textSpan = document.createElement('span')
    textSpan.textContent = 'Go Back'

    backButton.appendChild(textSpan);

    // Append back button to body
    document.body.appendChild(backButton)

    document.querySelector('.kiosk__back-button').addEventListener('click', () => {
        history.back();
    })
}

/**
 * Check if the back button should be shown
 *
 * @returns {Boolean}
 */
const shouldShowBackButton = () => {
    // Are we on the homepage?
    return window.location.pathname == '/' ? false : true
}
